import React, { useEffect, useState } from "react";
import "./App.css";
import logo from "./public/my_apps_icon.png";
import downloader_icon from "./public/downloader_icon.png";
import downloader_icon_direct from "./public/downloader_icon_direct.png";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import axios from "axios";

const BASE_URL = "https://api.myapps.tv";

function App() {
  const [app, setApp] = useState();

  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];

  useEffect(() => {
    axios
      .get(BASE_URL + "/data.json")
      .then((response) => {
        const currentApp = response.data.payload.find(
          (element) => currentPath ? element.name == currentPath : element.name == 'myapps'
        );
        setApp(currentApp);
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {};
  }, []);

  if (!app) {
    return (
      <div className="app_error">
        <div>
          <img src={logo} className="logo" />
        </div>
        <h1>Nothing found!!!</h1>
      </div>
    );
  }

  return (
    <div className="App">
      <div>
        <img src={logo} className="logo" />
      </div>

      <div className="main_items_container">
        <div className="main_item">
          <p>{app.title}</p>
          <img
            src={BASE_URL + "/public/" + app.name + ".png"}
            className="logo"
            style={{ borderRadius: 24 }}
          />
        </div>
        <div className="main_item">
          <p>Scan to Download</p>
          <QRCode value={app.direct_url} className="qr_code" />
        </div>
        <div className="main_item">
          <p>Or Download using:</p>
          {app.google_play_mobile_url ? (
            <div
              className="downloader_btn"
              onClick={() => {
                window.open(app.google_play_mobile_url, "_blank");
              }}
            >
              <img src={downloader_icon} className="downloader_img" />
              Mobile App
            </div>
          ) : (
            <div></div>
          )}

          {app.google_play_android_tv_url ? (
            <div
              className="downloader_btn"
              onClick={() => {
                window.open(app.google_play_android_tv_url, "_blank");
              }}
            >
              <img src={downloader_icon} className="downloader_img" />
              Android TV
            </div>
          ) : (
            <div></div>
          )}

          <div
            className="downloader_btn"
            onClick={() => {
              window.open(app.direct_url, "_blank");
            }}
          >
            <img src={downloader_icon_direct} className="downloader_img" />
            Direct Link
          </div>
        </div>
      </div>

      {app.downloader_code ? (
        <div className="instructions">
          <p style={{ fontSize: 22 }}>
            To Download the app in <strong>Android TV</strong> using Downloader
            app:
          </p>
          <ul>
            <li>
              Install{" "}
              <span
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.esaba.downloader",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                AFTV Downloader
              </span>{" "}
              in Google play
            </li>
            <li>
              Open the Downloader app and enter{" "}
              <strong>{app.downloader_code}</strong>
            </li>
          </ul>
        </div>
      ) : (
        <div></div>
      )}

      <div className="footer">
        <p>Contact info: <a href= "mailto: sales@onlineapp.tv">sales@onlineapp.tv</a></p>
      </div>
    </div>
  );
}

export default App;
